<template>
  <CSidebar fixed :minimize="minimize" :show.sync="show">
    <CSidebarBrand to="/">
      <img
        src="/img/brand/on-the-rolly-logo-white.png"
        class="c-sidebar-brand-full"
      />
      <img
        src="/img/brand/on-the-rolly-logo-white.png"
        class="c-sidebar-brand-minimized"
      />
    </CSidebarBrand>

    <CRenderFunction
      v-if="$store.getters.isAdmin"
      flat
      :content-to-render="AdminNav"
    />
    <CRenderFunction
      v-if="$store.getters.isLocationElite"
      flat
      :content-to-render="LocationEliteNav"
    />
    <CRenderFunction
      v-if="$store.getters.isLocationAdmin"
      flat
      :content-to-render="LocationAdminNav"
    />
    <CRenderFunction
      v-if="$store.getters.isLocationRegular"
      flat
      :content-to-render="LocationNav"
    />

    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="minimize = !minimize"
    />
  </CSidebar>
</template>

<script>
import AdminNav from "./_admin_nav";
import LocationEliteNav from "./_location_elite_nav";
import LocationAdminNav from "./_location_admin_nav";
import LocationNav from "./_location_nav";

export default {
  name: "TheSidebar",
  data() {
    return {
      minimize: false,
      AdminNav,
      LocationEliteNav,
      LocationAdminNav,
      LocationNav,
      show: "responsive",
    };
  },
  mounted() {
    this.$root.$on("toggle-sidebar", () => {
      const sidebarOpened = this.show === true || this.show === "responsive";
      this.show = sidebarOpened ? false : "responsive";
    });
    this.$root.$on("toggle-sidebar-mobile", () => {
      const sidebarClosed = this.show === "responsive" || this.show === false;
      this.show = sidebarClosed ? true : "responsive";
    });
  },
};
</script>
