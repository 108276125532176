<template>
  <div class="c-app">
    <CSpinner
    color="primary"
    style="width:4rem;height:4rem;"
    v-if="loading"
    />
    </CAlert>
    <transition name="fade">
        <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import { mapState } from 'vuex';

export default {
  name: 'AuthContainer',
  computed: mapState(['alert', 'loading']),
  watch: {
    alert(alertMessage) {
      console.log(alertMessage)
      if (alertMessage.message) {
        this.alertMessage = alertMessage.message
        this.alertType = alertMessage.type
        this.alertShow = true
        document.getElementById('body').scrollTo(0,0)
      } else {
        this.alertMessage = ''
        this.alertType = ''
        this.alertShow = false
      }
    }
  },
  data() {
    return {
      alertType: '',
      alertMessage: '',
      alertShow: false
    }
  },
  methods: {
  }
}

</script>

<style scoped>

.spinner-border {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  z-index: 10;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
