<template>
  <CFooter>
    <div class='footer-content'>
      <span class="ml-1">Design, Development & Hosting By <a href="https://kersversdigital.nl" target="_blank">Kersvers Digital.</a></span>
      <span class="ml-1 float-right">
        Support:
          <CIcon name="cil-screen-smartphone" height="15"/>
          <a href="tel:0850606646">085 06 06 646</a>
           - 
          <CIcon name="cil-envelope-closed" height="15"/>
          <a href="mailto:info@ontherolly.nl">info@ontherolly.nl</a> 
        </span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>

<style scoped>

  .footer-content {
    width: 100%;
  }

  .footer-content svg {
    margin-top: -3px;
    margin-right: 2px;
    margin-left: 5px;
  }

</style>
