export default [
	{
		_name: 'CSidebarNav',
		_children: [
			{
				_name: 'CSidebarNavItem',
				name: 'Dashboard',
				to: '/manager/dashboard/admin',
				icon: 'cil-speedometer',
			},

			{
				_name: 'CSidebarNavTitle',
				_children: ['Bookings'],
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Overview',
				to: '/manager/bookings',
				icon: 'cil-list',
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Create',
				to: '/manager/bookings/create',
				icon: 'cil-pencil',
			},

			{
				_name: 'CSidebarNavTitle',
				_children: ['Locations'],
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Overview',
				to: '/manager/locations',
				icon: 'cil-list',
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Create',
				to: '/manager/locations/create',
				icon: 'cil-pencil',
			},
			{
				_name: 'CSidebarNavTitle',
				_children: ['Customers'],
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Overview',
				to: '/manager/customers',
				icon: 'cil-list',
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Create',
				to: '/manager/customers/create',
				icon: 'cil-pencil',
			},
			{
				_name: 'CSidebarNavTitle',
				_children: ['Users'],
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Overview',
				to: '/manager/users',
				icon: 'cil-list',
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Create',
				to: '/manager/users/create',
				icon: 'cil-pencil',
			},

			{
				_name: 'CSidebarNavTitle',
				_children: ['Products'],
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Overview',
				to: '/manager/products',
				icon: 'cil-list',
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Create',
				to: '/manager/products/create',
				icon: 'cil-pencil',
			},

			{
				_name: 'CSidebarNavTitle',
				_children: ['Parts'],
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Overview',
				to: '/manager/parts',
				icon: 'cil-list',
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Create',
				to: '/manager/parts/create',
				icon: 'cil-pencil',
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Part Categories',
				to: '/manager/part-categories',
				icon: 'cil-list',
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Create Part Category',
				to: '/manager/part-categories/create',
				icon: 'cil-pencil',
			},

			{
				_name: 'CSidebarNavTitle',
				_children: ['Defects'],
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Overview',
				to: '/manager/defects',
				icon: 'cil-list',
			},

			{
				_name: 'CSidebarNavTitle',
				_children: ['Invoices'],
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Overview',
				to: '/manager/invoices',
				icon: 'cil-list',
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Create',
				to: '/manager/invoices/create',
				icon: 'cil-pencil',
			},
			{
				_name: 'CSidebarNavDivider',
				_class: 'm-2',
			},

			{
				_name: 'CSidebarNavTitle',
				_children: ['Projects'],
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Overview',
				to: '/manager/projects',
				icon: 'cil-list',
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Create',
				to: '/manager/projects/create',
				icon: 'cil-pencil',
			},
			{
				_name: 'CSidebarNavDivider',
				_class: 'm-2',
			},

			{
				_name: 'CSidebarNavTitle',
				_children: ['Articles'],
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Overview',
				to: '/manager/articles',
				icon: 'cil-list',
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Create',
				to: '/manager/articles/create',
				icon: 'cil-pencil',
			},
			{
				_name: 'CSidebarNavDivider',
				_class: 'm-2',
			},
		],
	},
];
