export default [
	{
		_name: 'CSidebarNav',
		_children: [
			{
				_name: 'CSidebarNavTitle',
				_children: ['Bookings'],
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Overview',
				to: '/manager/bookings',
				icon: 'cil-list',
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Create',
				to: '/manager/bookings/create',
				icon: 'cil-pencil',
			},

			{
				_name: 'CSidebarNavTitle',
				_children: ['Customers'],
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Overview',
				to: '/manager/customers',
				icon: 'cil-list',
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Create',
				to: '/manager/customers/create',
				icon: 'cil-pencil',
			},
			{
				_name: 'CSidebarNavTitle',
				_children: ['Users'],
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Overview',
				to: '/manager/users',
				icon: 'cil-list',
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Create',
				to: '/manager/users/create',
				icon: 'cil-pencil',
			},

			{
				_name: 'CSidebarNavTitle',
				_children: ['Products'],
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Overview',
				to: '/manager/products',
				icon: 'cil-list',
			},

			{
				_name: 'CSidebarNavTitle',
				_children: ['Defects'],
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Overview',
				to: '/manager/defects',
				icon: 'cil-list',
			},

			{
				_name: 'CSidebarNavTitle',
				_children: ['Invoices'],
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Overview',
				to: '/manager/invoices',
				icon: 'cil-list',
			},
			{
				_name: 'CSidebarNavDivider',
				_class: 'm-2',
			},
		],
	},
];
